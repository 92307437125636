.loading_view {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    font-family: Facundo, sans-serif;
    font-weight: 400;
    font-size: 1vw;
    line-height: 1vw;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #1a2f4a;
    background-color: #fff;
}