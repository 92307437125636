.video_component {
    width: 100vw;
    height: 100vh;
    max-width:100%;
    overflow: hidden;
    background-color: #f1f1f1;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        width: 100vw;
        height: 30vh;
        max-width:100%;
        background: linear-gradient(180deg, rgba(0,0,0,0.6) 0%, rgba(255,255,255,0) 100%);
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;
        width: 100vw;
        height: 30vh;
        max-width:100%;
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.6) 100%);
    }

    // &::after {
    //     content: '';
    //     position: absolute;
    //     bottom: 0;
    //     left: 0;
    //     z-index: 10;
    //     width: 100vw;
    //     height: 40vh;
    //     background: linear-gradient(180deg, rgba(255,255,255,0.0) 0%, rgba(255,255,255,0.3) 25%, rgba(255,255,255,0.6) 50%, rgba(255,255,255,0.8) 75%, rgba(255,255,255,1.0) 100%);
    // }
}

.video_component__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}