.distributors_view {

}

.distributors_view__regions {
    list-style-type: none;
    margin: 0;
    padding: 15vw 10vw;

    @media screen and (min-width: 960px) {
        padding: 6vw 10vw 8.5vw;
    }
}

.distributors_view__regions__item {
    margin: 0 0 1vw;
    padding: 0;
}

.distributors_view__regions__item__title {
    margin: 15vw 0 10vw;
    font-family: Facundo, sans-serif;
    font-size: 4.0vw;
    line-height: 6.5vw;
    letter-spacing: 0.1em;
    font-weight: 900;
    color: #009f4c;
    text-transform: uppercase;

    @media screen and (min-width: 960px) {
        margin: 0.8vw 0 1vw;
        font-size: 0.8vw;
        line-height: 1.5vw;
    }
}

.distributors_view__regions__item__distributors {
    list-style-type: none;
    margin: 0;
    padding: 0;

    @media screen and (min-width: 960px) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
    }
}

.distributors_view__regions__item__distributors__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 0 5vw;
    padding: 0;

    @media screen and (min-width: 960px) {
        margin: 0;
    }
}

.distributors_view__regions__item__distributors__item__header {
    margin: 0 0 3vw;
}

.distributors_view__regions__item__distributors__item__title {
    margin: 0 0 1vw;
    font-family: Facundo, sans-serif;
    font-weight: 900;
    font-size: 4.0vw;
    line-height: 6.5vw;
    letter-spacing: 0.1em;
    color: #1a2f4a;
    text-transform: uppercase;

    @media screen and (min-width: 960px) {
        font-size: 0.8vw;
        line-height: 1.5vw;
    }
}

.distributors_view__regions__item__distributors__item__location,
.distributors_view__regions__item__distributors__item__phone,
.distributors_view__regions__item__distributors__item__email {
    margin: 0;
    font-family: Facundo, sans-serif;
    font-weight: 400;
    font-size: 4.0vw;
    line-height: 6.5vw;
    letter-spacing: 0.1em;
    color: #1a2f4a;
    text-transform: uppercase;

    @media screen and (min-width: 960px) {
        font-size: 0.8vw;
        line-height: 1.5vw;
    }

    a {
        color: #1a2f4a;
    }
}

.distributors_view__regions__item__distributors__item__image {
    display: block;
    width: 100%;
}

.distributors_view__regions__item__distributors__item .button_component {
    width: 100%;
}