
@font-face {
  font-family: 'Facundo';
  font-weight: 400;
  font-style: normal;
  src: url(assets/fonts/facundo-regular.otf);
}

@font-face {
  font-family: 'Facundo';
  font-weight: 400;
  font-style: italic;
  src: url(assets/fonts/facundo-regular-italic.otf);
}

@font-face {
  font-family: 'Facundo';
  font-weight: 700;
  font-style: normal;
  src: url(assets/fonts/facundo-bold.otf);
}

@font-face {
  font-family: 'Facundo';
  font-weight: 700;
  font-style: italic;
  src: url(assets/fonts/facundo-bold-italic.otf);
}

@font-face {
  font-family: 'Facundo';
  font-weight: 900;
  font-style: normal;
  src: url(assets/fonts/facundo-black.ttf);
}

@font-face {
  font-family: 'Facundo';
  font-weight: 900;
  font-style: italic;
  src: url(assets/fonts/facundo-black-italic.otf);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.application_container {
  padding-top: 16vw;
}

@media screen and (min-width: 960px) {
  .application_container {
    padding-top: 4vw;
  }
}

.application_container.is_collapsible {
  padding-top: 0;
}

/* .application_container * {
  cursor: url(assets/images/cursor-1.png) 10 10, default;
} */

.heading {
  margin: 0;
  font-family: Facundo, sans-serif;
  font-weight: 400;
  font-size: 8vw;
  line-height: 11vw;
  letter-spacing: 0.03em;
  color: #000;
}

@media screen and (min-width: 960px) {
  .heading {
    font-size: 3vw;
    line-height: 3.55vw;
  }
}

.sub_heading {
  margin: 0;
  font-family: Facundo, sans-serif;
  font-weight: 900;
  font-size: 3vw;
  line-height: 4.5vw;
  letter-spacing: 0.11em;
  color: #009f4c;
  text-transform: uppercase;
}

@media screen and (min-width: 960px) {
  .sub_heading {
    font-size: 1.1vw;
    line-height: 1.5vw;
  }
}

.body {
  margin: 0 0 1em;
  font-family: Facundo, sans-serif;
  font-weight: 400;
  font-size: 3vw;
  line-height: 7.5vw;
  letter-spacing: 0.05em;
  color: #000;
}

@media screen and (min-width: 960px) {
  .body {
    font-size: 0.9vw;
    line-height: 1.5vw;
  }
}