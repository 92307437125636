.hero_component {
    position: relative;
    width: 100vw;
    height: 100vh;
    max-width:100%;
    box-sizing: border-box;
    background-color: #f1f1f1;
    overflow: hidden;
}

.hero_component__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.hero_component__list__item {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
}

.hero_component__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 20;
    width: 100%;
    // height: 100vh;
    padding: 0 10vw;
    box-sizing: border-box;
}

.hero_component__content__titles {
}

.hero_component__content__titles__subtitle {
    margin: 0 0 0 0.5vw;
    font-family: Facundo, sans-serif;
    font-weight: 900;
    font-size: 4.0vw;
    line-height: 6.5vw;
    letter-spacing: 0.1em;
    color: #f7f7f7;
    text-transform: uppercase;

    @media screen and (min-width: 960px) {
        font-size: 0.8vw;
        line-height: 1.5vw;
    }
}

.hero_component__content__titles__title {
    margin: 0;
    font-family: Facundo, sans-serif;
    font-weight: 400;
    font-size: 14vw;
    line-height: 14.5vw;
    letter-spacing: 0.05em;
    color: #f7f7f7;

    @media screen and (min-width: 960px) {
        font-size: 5vw;
        line-height: 5.5vw;
    }

    .animation {
        display: inline-block;

        .animation__outer {
            display: inline-block;

            &.animation__energising_africa {
                display: block;
            }
    
            .animation__energising_africa__inner {
                display: inline-block;
                animation: text-clip 1000ms 900ms cubic-bezier(0.5, 0, 0.1, 1) both;
            }
    
            .animation__with_solar__inner {
                display: inline-block;
                animation: text-clip 1000ms 900ms cubic-bezier(0.5, 0, 0.1, 1) both;
            }
        }
    }
}

.hero_component__content__cta {
    margin-top: 6vw;
    padding: 0 0 6vw;
    font-family: Facundo, sans-serif;
    font-weight: 400;
    font-size: 3vw;
    line-height: 5.5vw;
    letter-spacing: 0.05em;
    color: #f7f7f7;

    @media screen and (min-width: 960px) {
        margin-top: 1.6vw;
        padding: 0 0 3vw;
        font-size: 0.9vw;
        line-height: 1.5vw;
    }

    &__border {
        display: block;
        width: 100%;
        height: 1px;
        margin-bottom: 6vw;
        background-color: #f7f7f7;
        transform-origin: right;

        @media screen and (min-width: 960px) {
            margin-bottom: 1vw;
        }
    }

    &__outer {
        display: flex;
        flex-direction: column;
        align-self: flex-start;

        @media screen and (min-width: 960px) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        p {
            margin: 0;

            @media screen and (min-width: 960px) {

            }
        }

        .button_component {
            margin: 6vw 0 0;

            @media screen and (min-width: 960px) {
                margin: 0;
            }
        }
    }
}

@keyframes text-clip {
    from {
      clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
    }
    to {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
}