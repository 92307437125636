.category_view {
    min-height: 100vh;
    // padding-top: 20vh;
    // background-color: #f5f5f5;
}

.category_view__description {
    padding: 10vw 10vw;

    @media screen and (min-width: 960px) {

    }
}

.category_view__description__subtitle {
    margin: 0 0 0 0.5vw;
    font-family: Facundo, sans-serif;
    font-weight: 900;
    font-size: 4.0vw;
    line-height: 6.5vw;
    letter-spacing: 0.1em;
    color: #009f4c;
    text-transform: uppercase;

    @media screen and (min-width: 960px) {
        font-size: 0.8vw;
        line-height: 1.5vw;
    }
}

.category_view__description__title {
    margin: 0 0 2vw;
    font-family: Facundo, sans-serif;
    font-weight: 400;
    font-size: 8vw;
    line-height: 11vw;
    letter-spacing: 0.03em;
    color: #1a2f4a;

    @media screen and (min-width: 960px) {
        margin: 0 0 2vw;
        max-width: 50%;
        font-size: 3.5vw;
        line-height: 3.5vw;
    }
}
.category_view__description__content {
    font-family: Facundo, sans-serif;
    font-weight: 400;
    font-size: 3vw;
    line-height: 5.5vw;
    letter-spacing: 0.05em;
    color: #1a2f4a;

    @media screen and (min-width: 960px) {
        font-size: 0.9vw;
        line-height: 1.5vw;
    }
}

.category_view__description__navigation {
    margin: 10vw 0 0;

    @media screen and (min-width: 960px) {
        margin: 2vw 0 0;
    }
}

.category_view__description__navigation .button_component {
    margin: 0 0 2vw;

    @media screen and (min-width: 960px) {
        margin: 0 0.5vw 0 0;
    }
}

.category_view__children {

}

.category_view__children__category {

}

.category_view__children__category__hero {

    @media screen and (min-width: 960px) {
        position: relative;
        width: 100%;
        height: 100vh;
        overflow: hidden;
    }
}

.category_view__children__category__hero__image {
    display: block;
    width: 100%;
    height: auto;
    filter: brightness(1.1);

    @media screen and (min-width: 960px) {
        position: absolute;
        top: 0;
        right: 0;
        // width: 100%;
        width: auto;
        height: 100%;
        // object-fit: cover;
        // object-position: center;
        transform: translateX(10%);
    }

    .category_view__children__category:nth-child(even) & {

        @media screen and (min-width: 960px) {
            right: auto;
            left: 0;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1) translateX(20%);
        }
    }
}

.category_view__children__category__hero__content {
    padding: 15vw 10vw;

    @media screen and (min-width: 960px) {
        position: absolute;
        top: 50%;
        left: 10vw;
        max-width: 30vw;
        padding: 0;
        transform: translateY(-50%);
    }

    .category_view__children__category:nth-child(even) & {

        @media screen and (min-width: 960px) {
            right: 10vw;
            left: auto;
        }
    }
}

.category_view__children__category__hero__content__title {
    margin: 0 0 1vw;
    font-family: Facundo, sans-serif;
    font-weight: 900;
    font-size: 4.0vw;
    line-height: 6.5vw;
    letter-spacing: 0.1em;
    color: #009f4c;
    text-transform: uppercase;

    @media screen and (min-width: 960px) {
        font-size: 0.8vw;
        line-height: 1.5vw;
    }
}

.category_view__children__category__hero__content__description {
    margin: 0 0 2vw;
    font-family: Facundo, sans-serif;
    font-weight: 400;
    font-size: 3vw;
    line-height: 5.5vw;
    letter-spacing: 0.05em;
    color: #1a2f4a;

    @media screen and (min-width: 960px) {
        font-size: 0.9vw;
        line-height: 1.5vw;
    }
}

.category_view__children__category__hero__content__key_points {
    list-style-type: none;
    margin: 10vw 0 0;
    padding: 0;

    @media screen and (min-width: 960px) {
        margin: 0;
    }        
}

.category_view__children__category__hero__content__key_points__item {
    display: flex;
    align-items: center;
    margin: 0 0 1vw;
    padding: 0;

    @media screen and (min-width: 960px) {

    }

    &:before {
        content: "";
        display: inline-block;
        margin: 0 2vw 0 0;
        width: 3vw;
        height: 1px;
        background-color: #1a2f4a;

        @media screen and (min-width: 960px) {
            width: 1.1vw;
            margin: 0 0.8vw 0 0;
        }
    }

    span {
        display: inline-block;
        padding: 0.2vw 0 0;
        font-family: Facundo, sans-serif;
        font-size: 4vw;
        line-height: 8vw;
        font-weight: 900;
        color: #1a2f4a;

        @media screen and (min-width: 960px) {
            padding: 0.2vw 0 0;
            font-size: 0.75vw;
            line-height: 0.75vw;
        }
    }
}