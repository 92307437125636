.products_list {
    padding: 15vw 10vw;
    background-color: #f9f9f9;

    @media screen and (min-width: 960px) {
        padding: 6vw 5vw 8.5vw;
    }
}

.products_list__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.products_list__header__title {
    margin: 0 0 2vw;
    font-family: Facundo, sans-serif;
    font-weight: 900;
    font-size: 2.75vw;
    line-height: 6.5vw;
    letter-spacing: 0.1em;
    color: #009f4c;
    text-transform: uppercase;

    @media screen and (min-width: 960px) {
        margin: 0 0 1vw;
        font-size: 0.8vw;
        line-height: 1.5vw;
    }
}

.products_list__header__controls {

}

.products_list__header__controls__button {
    display: inline-block;
    margin: 0 0 0 5vw;
    padding: 0;
    border: none;
    outline: 0;
    background-color: transparent;
    cursor: pointer;

    @media screen and (min-width: 960px) {
        margin: 0 0 0 1vw;
    }

    &:first-child {
        margin: 0;
    }
}

.products_list__header__controls__button__image {
    display: inline-block;
    width: 1vw;
    height: 1vw;
    object-fit: contain;
    object-position: center;
    color: #1a2f4a;
}

.products_list__items_container {
    overflow: hidden;
}

.products_list__items {
    display: inline-flex;
    flex-wrap: nowrap;
    list-style-type: none;
    margin: 0;
    padding: 0;
    transition: transform 400ms ease-in-out;

    @media screen and (min-width: 960px) {
        // display: grid;
        // grid-template-columns: repeat(3, 1fr);
        column-gap: 2vw;
        // row-gap: 2vw;
        // flex: 0 1 30%;
    }
}

.products_list__items__item {
    width: 79vw;
    margin: 0;
    padding: 0;
    // box-shadow: 0 0 15px 0px rgba(100, 100, 100, 0.1);
    background-color: #fff;

    @media screen and (min-width: 960px) {
        // flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        width: 27.74vw;
        padding: 1vw;
        box-sizing: border-box;

        &:last-child {
            margin: 0;
        }
    }
}

.products_list__items__item__header {
    display: flex;
    flex-direction: column-reverse;

    @media screen and (min-width: 960px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;
        margin: 0 0 3vw;
    }
}

.products_list__items__item__header__info {
    margin: 8vw 0 0;

    @media screen and (min-width: 960px) {
        flex: 1;
        flex-basis: 50%;
        margin: 0;
        padding: 0 0 0 1vw;
    }
}

.products_list__items__item__header__info__dimensions {
    font-family: Facundo, sans-serif;
    font-weight: 900;
    font-size: 4.0vw;
    line-height: 6.5vw;
    letter-spacing: 0.1em;
    color: #1a2f4a;
    text-transform: uppercase;

    @media screen and (min-width: 960px) {
        font-size: 0.8vw;
        line-height: 1.5vw;
    }
}

.products_list__items__item__header__info__title {
    margin: 0;
    font-family: Facundo, sans-serif;
    font-weight: 900;
    font-size: 8vw;
    line-height: 8vw;
    letter-spacing: 0.03em;
    color: #1a2f4a;
    text-transform: uppercase;

    @media screen and (min-width: 960px) {
        font-size: 1.4vw;
        line-height: 2.5vw;
        letter-spacing: 0.1em;
    }
}

.products_list__items__item__header__info__product_type {
    margin: 0 0 8vw;
    font-family: Facundo, sans-serif;
    font-weight: 400;
    font-size: 4vw;
    line-height: 4vw;
    letter-spacing: 0.03em;
    color: #1a2f4a;
    text-transform: uppercase;

    @media screen and (min-width: 960px) {
        margin: 0;
        font-size: 0.7vw;
        line-height: 1vw;
        letter-spacing: 0.1em;
    }
}

.products_list__items__item__header__info__buttons {
    margin: 8vw 0 0;

    @media screen and (min-width: 960px) {
        margin: 1vw 0 0;
    }
}

.products_list__items__item__header__info .button_component {
    margin: 0 0 2vw;

    @media screen and (min-width: 960px) {
        margin: 0;
    }
}

.products_list__items__item__header__image {
    display: block;
    width: 100%;
    height: auto;

    @media screen and (min-width: 960px) {
        flex: 1;
        flex-basis: 50%;
        width: 50%;
        height: auto;
        // max-height: 60%;
        // align-self: center;
        // object-fit: contain;
        // object-position: center;
    }
}

.products_list__items__item__attributes {
    list-style-type: none;
    margin: 8vw 0 0;
    padding: 0;

    @media screen and (min-width: 960px) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 1px;
        row-gap: 1px;
        margin: 0;
        background-color: #1a2f4a;
    }
}

.products_list__items__item__attributes__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 4vw;
    padding: 0.5vw 0;
    background-color: #fff;

    @media screen and (min-width: 960px) {
        margin: 0;
    }
}

.products_list__items__item__attributes__item__value {
    display: block;
    margin: 0 0 0.3vw;
    font-family: Facundo, sans-serif;
    font-weight: 900;
    font-size: 5vw;
    line-height: 5vw;
    letter-spacing: 0.1em;
    color: #1a2f4a;
    text-transform: uppercase;

    @media screen and (min-width: 960px) {
        font-size: 0.5vw;
        line-height: 0.9vw;
    }
}

.products_list__items__item__attributes__item__name {
    font-family: Facundo, sans-serif;
    font-weight: 400;
    font-size: 3vw;
    line-height: 3vw;
    letter-spacing: 0.1em;
    color: #1a2f4a;
    text-transform: uppercase;

    @media screen and (min-width: 960px) {
        font-size: 0.4vw;
        line-height: 0.4vw;
    }
}