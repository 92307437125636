.footer_component {
    display: flex;
    flex-direction: column;
    // background-color: #e4e4e4;
}

.footer_component__logo {
    display: block;
    width: 40vw;
    height: auto;

    @media screen and (min-width: 960px) {
        width: 8vw;
    }
}

.footer_componet__content {
    display: flex;
    flex-wrap: wrap;
    padding: 15vw 10vw 7.5vw;

    @media screen and (min-width: 960px) {
        flex-wrap: nowrap;
        flex: 1;
        padding: 4vw 10vw;
    }
}

.footer_componet__content__column {
    display: flex;
    width: 50%;
    margin: 0 0 5vw;
    box-sizing: border-box;

    @media screen and (min-width: 960px) {
        display: block;
        flex: 1;
        width: auto;
        margin: 0;
        padding: 0 2vw 0 0;
    }

    &:last-child {
        padding: 0;
    }

    &.v_logo {
        width: 100%;
        margin: 0 0 7.5vw;
        padding: 0;

        @media screen and (min-width: 960px) {
            width: auto;
            margin: 0;
            padding: 0 2vw 0 0;
        }
    }

    &.v_contact {
        flex-direction: column-reverse;
        justify-content: flex-end;
        margin: 0;

        @media screen and (min-width: 960px) {
            flex-direction: none;
        }
    }
}

.footer_componet__content__column__title {
    margin: 0 0 1vw;
    // padding: 0 0 1vw;
    // border-bottom: 1px solid #adadad;
    font-family: Facundo, sans-serif;
    font-weight: 400;
    font-size: 2.5vw;
    line-height: 7vw;
    letter-spacing: 0.05em;
    color: #1a2f4a;
    // text-transform: uppercase;

    @media screen and (min-width: 960px) {
        margin: 0 0 2vw;
        font-size: 0.6vw;
        line-height: 1.5vw;
    }
}

.footer_componet__content__column__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.footer_componet__content__column__list__item {
    margin: 0;
    padding: 0;
}

.footer_componet__content__column__list__item__link {
    font-family: Facundo, sans-serif;
    font-weight: 400;
    font-size: 2.5vw;
    line-height: 7vw;
    letter-spacing: 0.05em;
    color: #1a2f4a;
    text-decoration: none;

    @media screen and (min-width: 960px) {
        font-size: 0.6vw;
        line-height: 1.5vw;
    }
}

.footer_componet__content__column__social_list {
    display: flex;
    list-style-type: none;
    margin: 5vw 0 0;
    padding: 0;

    @media screen and (min-width: 960px) {
        margin: 0;
    }
}

.footer_componet__content__column__social_list__item {
    margin: 0 1vw 0 0;
    padding: 0;
}

.footer_componet__content__column__social_list__link {
    display: inline-block;
}

.footer_componet__content__column__social_list__link__image {
    display: inline-block;
    width: auto;
    height: 7vw;

    @media screen and (min-width: 960px) {
        width: auto;
        height: 1vw;
    }
}

.footer_component__copyright {
    padding: 0 10vw 5vw;


    @media screen and (min-width: 960px) {
        padding: 1vw 0;
    }
}

.footer_component__copyright__text {
    font-family: Facundo, sans-serif;
    font-weight: 400;
    font-size: 2.5vw;
    line-height: 2.5vw;
    letter-spacing: 0.05em;
    color: #1a2f4a;
    
    @media screen and (min-width: 960px) {
        font-size: 0.5vw;
        line-height: 0.5vw;
        text-align: center;
    }
}