.button_component {
    display: block;
    position: relative;
    padding: 0 6vw;
    border: 1px solid #f7f7f7;
    box-sizing: border-box;
    border-radius: 0px;
    background-color: transparent;
    cursor: pointer;
    overflow: hidden;
    text-decoration: none;

    @media screen and (min-width: 960px) {
        display: inline-block;
        padding: 0 2vw;
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: #f7f7f7;
        transition: transform 600ms cubic-bezier(0.795, 0.055, 0.000, 1.035);
        transform: translateY(100%);
    }

    &:hover {

        &:before {
            transform: translateY(0%);
        }

        .button_component__label {
            opacity: 0;
            transform: rotateX(90deg);
        }

        .button_component__hover_label {
            opacity: 1;
            transform: rotateX(0deg);
        }
    }

    &.alt {
        border-color: #1a2f4a;

        &:before {
            background-color: #1a2f4a;
        }
    }
}

.button_component__label {
    display: block;
    font-family: Facundo, sans-serif;
    font-weight: 400;
    font-size: 4vw;
    line-height: 9.5vw;
    letter-spacing: 0.01em;
    color: #f7f7f7;
    text-align: center;
    opacity: 1;
    transform: rotateX(0deg);
    transition: transform 800ms cubic-bezier(0.795, 0.055, 0.000, 1.035), opacity 800ms cubic-bezier(0.795, 0.055, 0.000, 1.035);

    @media screen and (min-width: 960px) {
        font-size: 1vw;
        line-height: 2.5vw;
    }

    .alt & {
        color: #1a2f4a;
    }
}

.button_component__hover_label {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    font-family: Facundo, sans-serif;
    font-weight: 400;
    font-size: 4vw;
    line-height: 9.5vw;
    letter-spacing: 0.01em;
    text-align: center;
    color: #1a2f4a;
    opacity: 0;
    transform: rotateX(90deg);
    transition: transform 800ms cubic-bezier(0.795, 0.055, 0.000, 1.035), opacity 800ms cubic-bezier(0.795, 0.055, 0.000, 1.035);

    @media screen and (min-width: 960px) {
        font-size: 1vw;
        line-height: 2.5vw;
    }

    .alt & {
        color: #fff;
    }
}