.section_intro_component {
    padding: 15vw 10vw;

    @media screen and (min-width: 960px) {
        padding: 6vw 10vw 8.5vw;
    }
}

.section_intro_component__subtitle {
    margin: 0 0 0 0.5vw;
    font-family: Facundo, sans-serif;
    font-weight: 900;
    font-size: 4.0vw;
    line-height: 6.5vw;
    letter-spacing: 0.1em;
    color: #009f4c;
    text-transform: uppercase;

    @media screen and (min-width: 960px) {
        font-size: 0.8vw;
        line-height: 1.5vw;
    }
}

.section_intro_component__title {
    margin: 0 0 2vw;
    font-family: Facundo, sans-serif;
    font-weight: 400;
    font-size: 8vw;
    line-height: 11vw;
    letter-spacing: 0.03em;
    color: #1a2f4a;

    @media screen and (min-width: 960px) {
        max-width: 50%;
        font-size: 3vw;
        line-height: 3.5vw;
    }
}

.section_intro_component__description {
    margin: 0;
    font-family: Facundo, sans-serif;
    font-weight: 400;
    font-size: 3vw;
    line-height: 7.5vw;
    letter-spacing: 0.05em;
    color: #1a2f4a;

    @media screen and (min-width: 960px) {
        font-size: 0.9vw;
        line-height: 1.5vw;
    }
}