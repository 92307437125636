.categories_component {
    padding: 15vw 10vw;
    background-color: #f9f9f9;

    @media screen and (min-width: 960px) {
        padding: 8.5vw 10vw 8.5vw;
    }
}

.categories_component__title {
    margin: 0 0 2vw;
    font-family: Facundo, sans-serif;
    font-size: 4.0vw;
    line-height: 6.5vw;
    letter-spacing: 0.1em;
    font-weight: 900;
    color: #009f4c;
    text-transform: uppercase;

    @media screen and (min-width: 960px) {
        margin: 0 0 1vw;
        font-size: 0.8vw;
        line-height: 1.5vw;
    }
}

.categories_component__list {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: 0;
    padding: 0;

    @media screen and (min-width: 960px) {
        flex-direction: row;
        // grid-template-columns: repeat(3, 1fr);
        // column-gap: 2vw;
        // row-gap: 2vw;
    }
}

.categories_component__list__item {
    display: flex;
    flex: 1;
    flex-direction: column-reverse;
    margin: 0 0 5vw;
    padding: 0 8vw 8vw;
    background-color: #fff;
    box-sizing: border-box;

    @media screen and (min-width: 960px) {
        // flex-direction: row;
        margin: 0;
        padding: 2vw;
        border-right: 1px solid #f9f9f9;
        transition: flex 800ms cubic-bezier(0.650, 0.160, 0.000, 1.095);
    }

    &:last-child {

        @media screen and (min-width: 960px) {
            border-right: none;
        }
    }

    &.active {
        flex: 2;
    }
}

.categories_component__list__item__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    margin: 8vw 0 0;

    @media screen and (min-width: 960px) {
        margin: 0;
    }
}

.categories_component__list__item__content__subtitle {
    margin: 0;
    font-family: Facundo, sans-serif;
    font-weight: 900;
    font-size: 4.0vw;
    line-height: 6.5vw;
    letter-spacing: 0.1em;
    color: #009f4c;
    text-transform: uppercase;

    @media screen and (min-width: 960px) {
        font-size: 0.8vw;
        line-height: 1.5vw;
    }
}

.categories_component__list__item__content__title {
    margin: 0 0 8vw;
    font-family: Facundo, sans-serif;
    font-weight: 400;
    font-size: 8vw;
    line-height: 8vw;
    letter-spacing: 0.03em;
    color: #1a2f4a;
    text-transform: uppercase;

    @media screen and (min-width: 960px) {
        margin: 0 0 2vw;
        font-size: 1.8vw;
        line-height: 2.5vw;
        letter-spacing: 0.1em;
    }
}

.categories_component__list__item__content__footer {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.categories_component__list__item__content__footer__description {
    margin: 0 0 2vw;
    font-family: Facundo, sans-serif;
    font-weight: 400;
    font-size: 3vw;
    line-height: 7.5vw;
    letter-spacing: 0.05em;
    color: #1a2f4a;
    transition: opacity 800ms ease-in-out;

    @media screen and (min-width: 960px) {
        width: 17vw;
        font-size: 0.7vw;
        line-height: 1.2vw;
        opacity: 0;
    }

    .active & {

        @media screen and (min-width: 960px) {
            opacity: 1;
        }
    }

    p {
        margin: 0;
    }
}

.categories_component__list__item__content__footer__image {
    // display: none;
    width: 100%;
    height: auto;

    @media screen and (min-width: 960px) {
        width: 17vw;
        height: auto;
        // width: auto;
        // height: 7vw;
        // flex: 0 1;
        // align-self: center;
        // width: 40%;
    }
}