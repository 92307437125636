.home_view {

}

.home_view__about {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 15vw 10vw 5vw;
    box-sizing: border-box;
    background-color: #fff;

    @media screen and (min-width: 960px) {
        padding: 10vw 10vw 5vw;
    }
}

.home_view__about__body {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 960px) {
        width: 45vw;
    }
}

.home_view__about .heading {
    align-self: stretch;
}

.home_view__about__body__divider {
    display: block;
    width: 100%;
    height: 1px;
    margin: 5vw 0;
    background-color: #adadad;
    transform-origin: left;
    
    @media screen and (min-width: 960px) {

    }
}

.home_view__about__body .sub_heading {
    margin: 0 0 0;

    @media screen and (min-width: 960px) {
        margin: 0 0 2vw;
    }
}

.home_view__about__body .body .button_component {
    margin: 0 0 0;

    @media screen and (min-width: 960px) {
        margin: 0 0 2.5vw;
    }
}