.call_to_action_component {
    position: relative;
}

.call_to_action_component__image {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
}

.call_to_action_component__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 20;
    width: 100%;
    height: 100%;
    padding: 8vw 10vw;
    box-sizing: border-box;
}

.call_to_action_component__content__title {
    margin: 0 0 2vw;
    font-family: Facundo, sans-serif;
    font-weight: 400;
    font-size: 8vw;
    line-height: 11vw;
    letter-spacing: 0.03em;
    color: #fff;
    text-transform: uppercase;

    @media screen and (min-width: 960px) {
        max-width: 42%;
        margin: 0 0 1vw;
        font-size: 3.5vw;
        line-height: 3.5vw;
    }
}

.call_to_action_component__content__description {
    margin: 0 0 2vw;
    padding: 0;
    font-family: Facundo, sans-serif;
    font-weight: 400;
    font-size: 3vw;
    line-height: 5.5vw;
    letter-spacing: 0.05em;
    color: #f7f7f7;

    @media screen and (min-width: 960px) {
        max-width: 42%;
        margin: 0 0 1vw;
        padding: 0;
        font-size: 0.9vw;
        line-height: 1.5vw;
    }
}

.call_to_action_component__content__link {

    @media screen and (min-width: 960px) {
        max-width: 42%;
        align-self: flex-start;
    }
}