.embed_form {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.embed_form:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    z-index: 70;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(255,255, 255, 0.8);
}

.embed_form__modal {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    top: 20vh;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 80;
    width: 50vw;
    min-height: 30vw;
    background-color: #fff;
    box-shadow: 0px 0px 70px 70px #0000001f;
    box-sizing: border-box;
}

.embed_form__modal__header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 2vw 3vw 1vw;
    box-sizing: border-box;
}

.embed_form__modal__header__close_btn {
    border: none;
    background-color: transparent;
    cursor: pointer;

    img {
        display: inline-block;
        width: 1vw;
        height: 1vw;
    }
}

.embed_form__modal__content {
    width: 100%;
    padding: 0 3vw 2vw;
    box-sizing: border-box;

    iframe {
        border: none;
    }
}