.solutions_component {
    padding: 0 10vw 15vw;
    background-color: #f9f9f9;

    @media screen and (min-width: 960px) {
        padding: 8.5vw 10vw 8.5vw;
    }
}

.solutions_component__list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    @media screen and (min-width: 960px) {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 3vw;
        row-gap: 3vw;
    }
}

.solutions_component__list__item {
    position: relative;
    margin: 0 0 5vw;
    padding: 0;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        width: 100vw;
        height: 30vh;
        max-width:100%;
        background: linear-gradient(180deg, rgba(0,0,0,0.6) 0%, rgba(255,255,255,0) 100%);
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;
        width: 100vw;
        height: 30vh;
        max-width:100%;
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.6) 100%);
    }

    &:hover {

        .solutions_component__list__item__image {
            opacity: 1;
            transform: scale(1.01);
        }
    }
}

.solutions_component__list__item__image {
    display: block;
    width: 100%;
    height: 100vw;
    object-fit: cover;
    object-position: center bottom;
    transform-origin: center center;
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;

    @media screen and (min-width: 960px) {
        height: auto;
        opacity: 0.6;
    }
}

.solutions_component__list__item__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100%;
    height: 100%;
    padding: 5vw;
    box-sizing: border-box;

    @media screen and (min-width: 960px) {
        padding: 1vw;
    }
}

.solutions_component__list__item__content__header__title {
    margin: 0 0 2vw;
    font-family: Facundo, sans-serif;
    font-weight: 900;
    font-size: 4vw;
    line-height: 6vw;
    letter-spacing: 0.03em;
    color: #fff;
    text-transform: uppercase;

    @media screen and (min-width: 960px) {
        margin: 0 0 1vw;
        font-size: 1.0vw;
        line-height: 1.2vw;
    }
}

.solutions_component__list__item__content__header__description {
    margin: 0;
    padding: 0;
    font-family: Facundo, sans-serif;
    font-weight: 400;
    font-size: 3vw;
    line-height: 5.5vw;
    letter-spacing: 0.05em;
    color: #f7f7f7;

    @media screen and (min-width: 960px) {
        margin: 0;
        padding: 0;
        font-size: 0.7vw;
        line-height: 1.2vw;
    }
}

.solutions_component__list__item__content__footer {
    display: flex;
    flex-direction: column-reverse;
}

.solutions_component__list__item__content__footer__description {
    margin: 0 0 2vw;
    padding: 0;
    font-family: Facundo, sans-serif;
    font-weight: 400;
    font-size: 3vw;
    line-height: 5.5vw;
    letter-spacing: 0.05em;
    color: #f7f7f7;

    @media screen and (min-width: 960px) {
        margin: 0 0 1vw;
        padding: 0;
        font-size: 0.7vw;
        line-height: 1.2vw;
        opacity: 0;
        transition: opacity 300ms ease-in-out;
    }
}

.solutions_component__list__item__content__footer .solutions_component__list__item__content__footer__button:hover ~ .solutions_component__list__item__content__footer__description {
    opacity: 1;
}

.solutions_component__list__item__content__footer__button {
    display: block;
    position: relative;
    width: 100%;
    padding: 0 6vw;
    border: 1px solid #f7f7f7;
    box-sizing: border-box;
    border-radius: 0px;
    background-color: transparent;
    cursor: pointer;
    overflow: hidden;
    text-decoration: none;

    @media screen and (min-width: 960px) {
        display: inline-block;
        padding: 0 2vw;
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: #f7f7f7;
        transition: transform 600ms cubic-bezier(0.795, 0.055, 0.000, 1.035);
        transform: translateY(100%);
    }

    &:hover {

        &:before {
            transform: translateY(0%);
        }

        .solutions_component__list__item__content__footer__button__label {
            opacity: 0;
            transform: rotateX(90deg);
        }

        .solutions_component__list__item__content__footer__button__hover_label {
            opacity: 1;
            transform: rotateX(0deg);
        }
    }

    &.alt {
        border-color: #1a2f4a;

        &:before {
            background-color: #1a2f4a;
        }
    }
}

.solutions_component__list__item__content__footer__button__label {
    display: block;
    font-family: Facundo, sans-serif;
    font-weight: 400;
    font-size: 4vw;
    line-height: 9.5vw;
    letter-spacing: 0.01em;
    color: #f7f7f7;
    text-align: center;
    opacity: 1;
    transform: rotateX(0deg);
    transition: transform 800ms cubic-bezier(0.795, 0.055, 0.000, 1.035), opacity 800ms cubic-bezier(0.795, 0.055, 0.000, 1.035);

    @media screen and (min-width: 960px) {
        font-size: 1vw;
        line-height: 2.5vw;
    }

    .alt & {
        color: #1a2f4a;
    }
}

.solutions_component__list__item__content__footer__button__hover_label {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    font-family: Facundo, sans-serif;
    font-weight: 400;
    font-size: 4vw;
    line-height: 9.5vw;
    letter-spacing: 0.01em;
    text-align: center;
    color: #1a2f4a;
    opacity: 0;
    transform: rotateX(90deg);
    transition: transform 800ms cubic-bezier(0.795, 0.055, 0.000, 1.035), opacity 800ms cubic-bezier(0.795, 0.055, 0.000, 1.035);

    @media screen and (min-width: 960px) {
        font-size: 1vw;
        line-height: 2.5vw;
    }

    .alt & {
        color: #fff;
    }
}