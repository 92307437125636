.product_view {

}

.product_view__content {
    padding: 5vw 10vw;
    background-color: #fff;

    @media screen and (min-width: 960px) {
        padding: 5vw 10vw;
    }
}

.product_view__content__dimensions {
}

.product_view__content__attributes {
    
    list-style-type: none;
    margin: 0 0 10vw;
    padding: 1vw;
    border: 1px solid #adadad2a;
    background-color: #fff;
    box-shadow: 0px 0px 75px 40px rgba(172, 173, 173, 0.3);

    @media screen and (min-width: 960px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.product_view__content__attributes__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 1vw 0;
    border-bottom: 1px solid #adadad75;
    font-family: Facundo, sans-serif;
    font-weight: 900;
    font-size: 4.0vw;
    line-height: 6.5vw;
    letter-spacing: 0.1em;
    color: #1a2f4a;
    text-transform: uppercase;

    &:last-child {
        border-bottom: none;
    }

    @media screen and (min-width: 960px) {
        border-right: 1px solid #adadad75;
        border-bottom: none;
        font-size: 0.8vw;
        line-height: 1.5vw;
    }

    &:last-child {
        border-right: none;
    }
}

.product_view__content__attributes__item__value {
    font-size: 6.0vw;
    line-height: 8.5vw;

    @media screen and (min-width: 960px) {
        font-size: 1vw;
        line-height: 2vw;
    }
}

.product_view__content__attributes__item__name {
    font-weight: 400;
}

.product_view__content__details {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 960px) {
        flex-direction: row;
    }
}

.product_view__content__details__key_points_list {
    order: 2;
    list-style-type: none;
    margin: 0;
    padding: 0;

    @media screen and (min-width: 960px) {
        order: inherit;
        flex: 1;
    }
}

.product_view__content__details__key_points_list__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 1vw;
    padding: 0;
}

.product_view__content__details__key_points_list__item__icon {
    display: block;
    margin: 0.5vw;
}

.product_view__content__details__key_points_list__item__icon__src {
    width: 10vw;
    height: auto;

    @media screen and (min-width: 960px) {
        width: 3vw;
    }
}

.product_view__content__details__key_points_list__item__title {
    font-family: Facundo, sans-serif;
    font-weight: 900;
    font-size: 4.0vw;
    line-height: 6.5vw;
    letter-spacing: 0.1em;
    color: #1a2f4a;
    text-transform: uppercase;
    text-align: center;

    @media screen and (min-width: 960px) {
        font-size: 0.8vw;
        line-height: 1.5vw;
    }
}

.product_view__content__details__key_points_list__item__description {
    font-family: Facundo, sans-serif;
    font-weight: 400;
    font-size: 3.0vw;
    line-height: 4.5vw;
    letter-spacing: 0.1em;
    color: #1a2f4a;
    text-align: center;

    @media screen and (min-width: 960px) {
        font-size: 0.6vw;
        line-height: 1vw;
    }
}


.product_view__content__details__image {
    order: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 960px) {
        order: inherit;
        flex: 2;
    }
}

.product_view__content__details__image__src {
    display: block;
    width: 100%;
    height: auto;

    @media screen and (min-width: 960px) {
        width: 15vw;
    }
}