.downloads_view {

}

.downloads_view__downloads {
    padding: 15vw 10vw 0;

    @media screen and (min-width: 960px) {
        display: flex;
        padding: 6vw 10vw 0;
    }
}

.downloads_view__downloads__filter {
    flex: 1;
    list-style-type: none;
    margin: 0 0 5vw;
    padding: 0;
    background-color: #f9f9f9;

    @media screen and (min-width: 960px) {
        margin: 0;
    }
}

.downloads_view__downloads__filter__item {
    margin: 0;
    padding: 0;
    font-family: Facundo, sans-serif;
    font-weight: 900;
    font-size: 4.0vw;
    line-height: 6.5vw;
    letter-spacing: 0.1em;
    color: #1a2f4a;
    text-transform: uppercase;

    @media screen and (min-width: 960px) {
        padding: 1vw 2vw;
        font-size: 0.8vw;
        line-height: 1.5vw;
    }

    &:last-child {
        border-right: none;

        > span {
            padding-bottom: 0;
        }
    }

    > span {
        display: block;
        padding: 1vw 0;
        border-bottom: 1px solid #e3e3e3;
    }
}

.downloads_view__downloads__filter__item__sub_categories {
    list-style-type: none;
    margin: 0;
    padding: 1vw 0 0;
}

.downloads_view__downloads__filter__item__sub_categories__item {
    margin: 0;
    padding: 1vw 0;
    font-weight: 400;
    cursor: pointer;
}

.downloads_view__downloads__list {
    flex: 3;
    list-style-type: none;
    margin: 0;
    padding: 5vw 2vw;
    border-top: 1px solid #e3e3e3;

    @media screen and (min-width: 960px) {
        padding: 0 2vw;
        border-top: none;
    }
}

.downloads_view__downloads__list__item {
    display: flex;
    flex-direction: column;
    margin: 0 0 3vw;
    padding: 0;
    border-top: 1px solid #f9f9f9;
    font-family: Facundo, sans-serif;
    font-weight: 400;
    font-size: 4.0vw;
    line-height: 6.5vw;
    letter-spacing: 0.1em;
    color: #1a2f4a;
    text-transform: uppercase;
    background-color: #fff;

    @media screen and (min-width: 960px) {
        flex-direction: row;
        margin: 0;
        padding: 1vw;
        font-size: 0.8vw;
        line-height: 1.5vw;
    }

    &.v_title {
        border-top: none;

        span {
            flex: 1;

            &.downloads_view__downloads__list__item__label__title {
                flex: 4;
            }
        }
    }

    a {
        color: #1a2f4a;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.downloads_view__downloads__list__item__title {
    margin: 0;

    @media screen and (min-width: 960px) {
        flex: 4;
    }
}

.downloads_view__downloads__list__item__certificate {

    @media screen and (min-width: 960px) {
        flex: 1;
    }
}

.downloads_view__downloads__list__item__warranty {

    @media screen and (min-width: 960px) {
        flex: 1;
        display: flex;
        align-items: center;
    }
}

.downloads_view__downloads__list__item__date_published {
    font-family: Facundo, sans-serif;
    font-weight: 900;
    font-size: 4.0vw;
    line-height: 6.5vw;
    letter-spacing: 0.1em;
    color: #1a2f4a;
    text-transform: uppercase;

    @media screen and (min-width: 960px) {
        flex: 1;
        font-size: 0.8vw;
        line-height: 1.5vw;
    }
}

.downloads_view__downloads__list__item__view_btn {
    font-family: Facundo, sans-serif;
    font-weight: 900;
    font-size: 4.0vw;
    line-height: 6.5vw;
    letter-spacing: 0.1em;
    color: #1a2f4a;
    text-transform: uppercase;
    text-decoration: none;

    @media screen and (min-width: 960px) {
        flex: 1;
        font-size: 0.8vw;
        line-height: 1.5vw;
        text-align: right;
    }
}