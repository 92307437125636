.header_component {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100vw;
    height: 22vw;
    max-width: 100%;
    padding: 3vw 7vw 3vw 6vw;
    box-sizing: border-box;
    transition: height 400ms cubic-bezier(0.795, 0.055, 0.000, 1.035);

    @media screen and (min-width: 960px) {
        height: 8vw;
        padding: 1vw 3vw;
    }

    &.v_scroll {
        height: 16vw;
        border-bottom: 1px solid #ebebeb;
        box-shadow: 0px 3px 27px 0px rgba(0,0,0,0.3);
        background-color: #fff;

        @media screen and (min-width: 960px) {
            height: 4vw;
        }

        .header_component__logo__image.v_initial {
            transform: translateY(-20%);
            opacity: 0;
        }

        .header_component__logo__image.v_scroll {
            transform: translateY(0%);
            opacity: 1;
        }

        .header_component__menu__list__item__link {
            color: #009f4c;

            @media screen and (min-width: 960px) {
                color: black;
            }

            &:hover {
                color: #009f4c;
            }

            &:after {
                background-color: #009f4c;
            }
        }

        .header_component__sub_menu__list__item__link {
            color: #1a2f4a;
        }

        .header_component__sub_menu__list__item__link__icon {

            &.v_initial {
                opacity: 0;
            }

            &.v_scroll {
                opacity: 1;
            }
        }
    }
}

.header_component__logo {
    display: block;
    position: relative;
    text-decoration: none;
}

.header_component__logo__image {
    display: block;
    width: 22vw;
    height: auto;
    transition: transform 400ms cubic-bezier(0.795, 0.055, 0.000, 1.035), opacity 100ms cubic-bezier(0.795, 0.055, 0.000, 1.035);

    @media screen and (min-width: 960px) {
        width: 8vw;
    }

    &.v_initial {
        transform: translateY(0%);
        opacity: 1;
    }

    &.v_scroll {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(20%);
        opacity: 0;
    }
}

.header_component__menu {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: -100vw;
    z-index: 80;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.98);
    transition: right 400ms cubic-bezier(0.795, 0.055, 0.000, 1.035);

    @media screen and (min-width: 960px) {
        flex-direction: row;
        position: static;
        top: auto;
        right: auto;
        width: auto;
        height: auto;
        background-color: transparent;
    }

    &.is_visible {
        right: 0;
    }
}

.header_component__menu__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3vw 6vw 0;

    @media screen and (min-width: 960px) {
        display: none;
    }
}

.header_component__menu__header__logo {
    display: block;
    position: relative;
    text-decoration: none;
}

.header_component__menu__header__logo__image {
    display: block;
    width: 22vw;
    height: auto;

    @media screen and (min-width: 960px) {
        display: none;
    }
}

.header_component__menu__header__close_button {
    display: block;
    position: relative;
    width: 8vw;
    height: 8vw;
    padding: 0;
    border: none;
    background-color: transparent;
    opacity: 1;
    
    &:hover {
        opacity: 1;
    }

    &:before, 
    &:after {
        content: " ";
        position: absolute;
        left: 4vw;
        top: 0;
        height: 8vw;
        width: 1px;
        background-color: #009f4c;
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }
}

.header_component__menu__list {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0;

    @media screen and (min-width: 960px) {
        flex-direction: row;
    }
}

.header_component__menu__list__item {
    margin: 0;
    padding: 0;
}

.header_component__menu__list__item__link {
    display: flex;
    flex-direction: column;
    padding: 0 0.7vw;
    font-family: Facundo, sans-serif;
    font-weight: 700;
    font-size: 5vw;
    line-height: 10vw;
    letter-spacing: 0.05em;
    text-decoration: none;
    color: #009f4c;

    @media screen and (min-width: 960px) {
        font-size: 0.8vw;
        line-height: 2vw;
        color: #fff;
    }

    .is_visible & {
        animation: text-clip 1000ms 900ms cubic-bezier(0.5, 0, 0.1, 1) both;
    }

    &:hover {

        &:after {
            width: 100%;
        }
    }

    &:after {
        content: '';
        display: block;
        width: 0%;
        height: 3px;
        background-color: #fff;
        opacity: 0.7;
        transition: width 500ms cubic-bezier(0.795, 0.055, 0.000, 1.035);
    }
}

.header_component__sub_menu {

}

.header_component__sub_menu__list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.header_component__sub_menu__list__item {
    margin: 0;
    padding: 0;

    &.v_sub_menu_item,
    &.v_lanaguage {
        display: none;

        @media screen and (min-width: 960px) {
            display: block;
        }
    }

    &.v_menu_button {

        @media screen and (min-width: 960px) {
            display: none;
        }
    }
}

.header_component__sub_menu__list__item__link {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 0 0 5vw;
    border: none;
    font-family: Facundo, sans-serif;
    font-weight: 400;
    font-size: 2.5vw;
    line-height: 10vw;
    letter-spacing: 0.03em;
    text-decoration: none;
    color: #fff;
    background-color: transparent;
    cursor: pointer;

    @media screen and (min-width: 960px) {
        padding: 0 0 0 1vw;
        font-size: 0.5vw;
        line-height: 2vw;
    }

    &:hover {
    }

    &__menu_icon {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 9vw;
        height: 5vw;

        span {
            display: block;
            width: 100%;
            height: 1px;
            background-color: #fff;

            .v_scroll & {
                background-color: #1a2f4a;
            }
        }
    }
}

.header_component__sub_menu__list__item__link__icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 0 0 0.5vw;

    &.v_initial {
        opacity: 1;
    }

    &.v_scroll {
        position: absolute;
        right: 0;
        opacity: 0;
    }
}

@media screen and (max-width: 959px) {
    @keyframes text-clip {
        from {
        clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
        }
        to {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }
    }
}