.page_hero_component {
    position: relative;
    width: 100%;
    height: 50vh;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 20vh;
        max-width:100%;
        background: linear-gradient(180deg, rgba(0,0,0,0.6) 0%, rgba(255,255,255,0) 100%);
    }
    
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 30vh;
        max-width:100%;
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.6) 100%);
    }
}

.page_hero_component__image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
}

.page_hero_component__content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 10vw;
    box-sizing: border-box;
    z-index: 20;
}

.page_hero_component__content__subtitle {
    margin: 0 0 0 0.5vw;
    font-family: Facundo, sans-serif;
    font-weight: 900;
    font-size: 4.0vw;
    line-height: 6.5vw;
    letter-spacing: 0.1em;
    color: #f7f7f7;
    text-transform: uppercase;

    @media screen and (min-width: 960px) {
        font-size: 0.8vw;
        line-height: 1.5vw;
    }
}

.page_hero_component__content__title {
    margin: 0;
    font-family: Facundo, sans-serif;
    font-weight: 400;
    font-size: 14vw;
    line-height: 14.5vw;
    letter-spacing: 0.05em;
    color: #f7f7f7;

    @media screen and (min-width: 960px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // width: 75%;
        font-size: 5vw;
        line-height: 5.5vw;
    }

    .button_component {
        margin: 2vw;

        @media screen and (min-width: 960px) {
            margin: 0;
        }
    }
}

.page_hero_component__content__introduction {
    padding: 0 0 6vw;
    font-family: Facundo, sans-serif;
    font-weight: 400;
    font-size: 3vw;
    line-height: 5.5vw;
    letter-spacing: 0.05em;
    color: #f7f7f7;

    @media screen and (min-width: 960px) {
        padding: 0 0 3vw;
        font-size: 0.9vw;
        line-height: 1.5vw;
    }
}