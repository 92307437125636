.about_view {
    // padding: 0 0 15vw;

    @media screen and (min-width: 960px) {
        // padding: 0 0 8.5vw;
    }
}

.about_view__content_blocks {
    display: flex;  
    margin: 4.5vw 0 0;

    @media screen and (min-width: 960px) {
        margin: 2.5vw 0 0;
    }
}

.about_view__content_blocks {

    @media screen and (min-width: 960px) {
    }
}

.about_view__content_blocks__list {
    list-style-type: none;
    margin: 0;
    padding: 15vw 0;

    @media screen and (min-width: 960px) {
        padding: 6vw 0 8.5vw;
    }
}

.about_view__content_blocks__list__item {
    margin: 0 0 15vw;
    padding: 0 10vw;

    @media screen and (min-width: 960px) {
        display: flex;
        align-items: center;
        margin: 0 0 6vw;
        padding: 0 10vw;
    }

    &:nth-child(even) {
        padding: 15vw;
        background-color: #f0f0f0a8;

        @media screen and (min-width: 960px) {
            padding: 5vw 10vw;
        }
    }

    &:last-child {
        margin: 0;
    }
}

.about_view__content_blocks__list__item__content {

    @media screen and (min-width: 960px) {
        flex: 1;
    }

    *:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

.about_view__content_blocks__list__item__content__title {
    margin: 0 0 1vw;
    font-family: Facundo, sans-serif;
    font-weight: 900;
    font-size: 4.0vw;
    line-height: 6.5vw;
    letter-spacing: 0.1em;
    color: #009f4c;
    text-transform: uppercase;

    @media screen and (min-width: 960px) {
        font-size: 0.8vw;
        line-height: 1.5vw;
    }
}

.about_view__content_blocks__list__item__content__text {
    font-family: Facundo, sans-serif;
    font-weight: 400;
    font-size: 3vw;
    line-height: 7.5vw;
    letter-spacing: 0.05em;
    color: #1a2f4a;

    @media screen and (min-width: 960px) {
        font-size: 0.9vw;
        line-height: 1.5vw;
    }

    p {
        margin: 0;
        padding: 0 0 1vw;
    }
}

.about_view__content_blocks__list__item__image {
    padding: 0 0 5vw;

    @media screen and (min-width: 960px) {
        flex: 1;
        padding: 0 5vw 0 0;
    }
}

.about_view__content_blocks__list__item__image__src {
    display: block;
    width: 100%;
    height: auto;
}